<template>
  <v-select
    :items="reservation_system_items"
    item-text="text"
    item-value="value"
    v-model="reservation_system"
    outlined
    dense
    hide-details
    :disabled="disabled"
  >
    <template v-slot:item="{ item }">
      <div class="pb-1">{{ item.text }}</div>
      <!--<v-tooltip v-if="item.value != 'day'" right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon x-small v-bind="attrs" v-on="on">$info </v-icon>
        </template>
        <div>Lorem ipsum dolor sit amet...</div>
      </v-tooltip>-->
    </template>
  </v-select>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LaborExangeSelect",
  data() {
    return {
      reservation_system_items: [
        { text: this.$t("reservation_systems.day"), value: "day" },
        { text: this.$t("reservation_systems.week"), value: "week" },
        { text: this.$t("reservation_systems.month"), value: "month" },
      ],
    };
  },
  methods: {
    ...mapActions("users", ["settingUpdate"]),
  },
  props: ["disabled", "setting_key"],
  computed: {
    reservation_system: {
      get() {
        return this.$store.getters["auth/getSetting"](this.setting_key);
      },
      set(value) {
        //llamamos a la funcion de crear setting
        console.log(this.setting_key);
        this.$confirm(this.$t("change_labor_exange")).then(() => {
          this.settingUpdate({ key: this.setting_key, value }).then((data) => {
            this.$alert($t("activated"));
          });
        });
      },
    },
  },
};
</script>
<style lang="sass">
.v-select-list .v-list-item__title, .v-menu__content .v-list .v-list-item__title, .v-menu__content .v-list--nav .v-list-item .v-list-item__title, .v-menu__content .v-list--nav .v-list-item, .v-menu__content.v-menu__content .theme--dark.v-list-item, .theme--dark.v-list-item--disabled
  //padding-bottom: 5px
</style>